import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Text } from "../../elements/Text";
import { ExternalLink } from "../../elements/ExternalLink";
import { Image } from "../../elements/Image";
import { IMAGES } from "../../utils/assets";
import { Button } from "../../elements/Button";
import { Link } from "gatsby";

const AboutPage = () => {
  return (
    <>
      <Flex bg="pink" p={5} justifyContent="center">
        <Heading>About</Heading>
      </Flex>

      <Flex
        py={5}
        px={3}
        textAlign="center"
        flexDirection="column"
        maxWidth="990px"
        margin="0 auto"
      >
        <Text mb={4}>
          I’m Lilly Sabri, founder of Lean with Lilly. I’ve been a Chartered
          Physiotherapist since 2010, APPI Pilates instructor, Fitness, Heath and
          Lifestyle Influencer.
        </Text>

        <Text mb={4}>
          Over the years, I’ve educated and empowered hundreds of thousands of people
          around the world to live a healthier lifestyle in a way that encourages
          increased body confidence regardless of shape, size and fitness level. Their
          transformations have been my driving force.
        </Text>

        <Text mb={4}>
          My aim is to create a supportive community, accessible to anyone wanting to take
          control and make changes towards leading a healthier, happier lifestyle!
        </Text>

        <Text mb={4}>
          I've always been sports obsessed and from a young age, trying out as many sports
          as I could! This eventually fueled my ambition to study Physiotherapy at
          University, where I found myself drawn to the sports side of the profession
          (shock... ).
        </Text>

        <Text mb={4}>
          Eventually I found myself working with elite athletes, focusing primarily on
          footballers and rugby players. Working with{" "}
          <ExternalLink
            color="grey"
            href="https://www.chelseafc.com/en"
            title="https://www.chelseafc.com/en"
            aria-describedby="a11y-external-message"
          >
            Chelsea FC
          </ExternalLink>{" "}
          <ExternalLink
            color="grey"
            href="https://www.watfordfc.com/"
            title="https://www.watfordfc.com/"
            aria-describedby="a11y-external-message"
          >
            Watford FC
          </ExternalLink>{" "}
          <ExternalLink
            color="grey"
            href="https://www.barnetfc.com/"
            title="https://www.barnetfc.com/"
            aria-describedby="a11y-external-message"
          >
            Barnet FC
          </ExternalLink>{" "}
          and the{" "}
          <ExternalLink
            color="grey"
            href="http://www.thefa.com/england/womens-seniors"
            title="http://www.thefa.com/england/womens-seniors"
            aria-describedby="a11y-external-message"
          >
            England Women's Football Team
          </ExternalLink>{" "}
          I developed my knowledge as a sports physiotherapist.
        </Text>

        <Text mb={4}>
          I've now taken my years of physio experience and poured it into{" "}
          <ExternalLink
            color="grey"
            href="http://eepurl.com/dLBIRA"
            title="http://eepurl.com/dLBIRA"
            aria-describedby="a11y-external-message"
          >
            my guides, workout videos,
          </ExternalLink>{" "}
          and more to develop a fit, confident and healthy lifestyle for myself and
          others!
        </Text>

        <Text mb={3}>
          Join the #LeanWithLilly family to take control, feel confident and make changes
          towards a healthier, happier lifestyle!
        </Text>

        <Link to="/lean-app">
          <Button mx="auto" mb={4} variant="darkTeal">
            Get the LEAN app
          </Button>
        </Link>

        <Text mb={3} fontWeight="600">
          Lilly Sabri, BSc Physiotherapy, MCSP, MHPC, MAACP, MACSPM
        </Text>

        <ExternalLink
          textDecoration="none"
          color="grey"
          href="http://eepurl.com/dLBIRA"
          title="http://eepurl.com/dLBIRA"
          aria-describedby="a11y-external-message"
        >
          <Heading>JOIN THE #LEANWITHLILLY FAMILY</Heading>
        </ExternalLink>

        <Image src={IMAGES.about1} alt="About" my={4} />
        <Image src={IMAGES.about2} alt="About" />
      </Flex>
    </>
  );
};

export default AboutPage;
